<template>
  <div class="container">
    <h2 class="question">Er du interessert i domenet tennøe.com?</h2>
    <div class="buttons">
      <button class="btn btn-yes" @click="handleYes">Ja</button>
      <button class="btn btn-no" @click="handleNo">Nei</button>
    </div>
    <div v-if="showMessage" class="message">
      Ta kontakt med Johannes T. E.
    </div>
  </div>
</template>

<script>
export default {
  name: 'DomainInterest',
  data() {
    return {
      showMessage: false,
    }
  },
  methods: {
    handleYes() {
      this.showMessage = true;
    },
    handleNo() {
      this.showMessage = false;
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 600px;
  width: 90%;
  margin: 50px auto;
  text-align: center;
  padding: 20px;
  padding-top: 120px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Only apply a larger scale on desktop screens */
  transform: scale(1.5);
}

/* Adjust styles for mobile devices */
@media (max-width: 600px) {
  .container {
    width: 95%;
    margin: 20px auto;
    padding: 15px;
    padding-top: 60px;
    transform: scale(1); /* Reset scaling for smaller screens */
  }
  .question {
    font-size: 1.5em;
  }
  .btn {
    font-size: 1em;
    padding: 10px 20px;
    margin: 5px;
  }
  .message {
    font-size: 1.2em;
  }
}

.question {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.buttons {
  margin-bottom: 20px;
}

.btn {
  font-size: 1.2em;
  padding: 15px 30px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-yes {
  background-color: #4caf50;
  color: #fff;
}

.btn-no {
  background-color: #f44336;
  color: #fff;
}

.message {
  font-size: 1.2em;
  color: #333;
  margin-top: 20px;
}
</style>
